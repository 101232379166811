import React from 'react';
import { UserSettingsToggles } from '@/legacy-types';
import { AnswserMap } from '../pages/manage/components/SurveyInsightsDrawer';

export const SurveyTypes = ['customer', 'crew'] as const;
export type SurveyType = (typeof SurveyTypes)[number];

export type MiniContactInfo = { email: string; phone: string };

export interface MacroSurvey {
	id: string;
	name: string;
	description: string;
	endDate: string;
}

export interface Survey {
	id: string;
	uid: string;
	name: string;
	description: string;
	redirectURL: string;

	type: (typeof SurveyTypes)[number];
	questions: SurveyQuestion[];

	allowAnon?: boolean; // Optionally don't require phone/email on submission. Allow responses to not link to personas
	archived: boolean;

	startDate: string;
	endDate: string;

	// Restricted to personas with fav store IDs in this slice if not empty. Only restricts if AllowAnon = false.
	locations: string[];

	created: string;
	updated: string;

	config: SurveyConfig;
}

export interface SurveyWithInsights {
	survey: Survey;
	insights: SurveyQuestionInsight[];
	visits: number;
	sends: number;
}

export interface Survey_Cleaned extends Survey {
	visits: number;
	sends: number;

	lastSubmission?: number;
	submissions?: number;
}

export type SignupError = { id: string; label: string; message: string };
export interface SurveyQuestion {
	id: string;
	label: string;
	placeholder?: string;
	value: string | number | boolean | string[];
	type: SurveyQuestionType;
	required: boolean;
	disabled?: boolean;
	options?: string[];
	style?: {
		[key: string]: any;
	} & React.CSSProperties;
	priority: number;

	validator?: string; // Regex string to validate input
	validatorMessage?: string; // Message to display if validation fails
}

// export type SurveyQuestionType = keyof typeof SurveyQuestionConfig
export const requiresOptions = ['select', 'multi-select', 'radio', 'radio-vertical'] as SurveyQuestionType[];
export type SurveyQuestionConfigItem = {
	title: string;
	description: string;
	options?: boolean;
	placeholder?: boolean;
	isBoolean?: boolean;
	validator?: boolean;
};
const questionConfigs = {
	'input': {
		title: 'Text Input',
		description: 'A simple text input field.',
		options: false,
		placeholder: true,
		validator: true,
	},
	'number': {
		title: 'Number Input',
		description: 'A simple number input field.',
		options: false,
		placeholder: true,
	},
	'textarea': {
		title: 'Text Area Input',
		description: 'A simple text area input field.',
		options: false,
		placeholder: true,
	},
	'select': {
		title: 'Select',
		description: 'A select input field.',
		options: true,
		placeholder: true,
	},
	'multi-select': {
		title: 'Multi-Select',
		description: 'A multi-select input field.',
		options: true,
		placeholder: true,
	},
	'radio-vertical': {
		title: 'Radio Select (Vertical)',
		description: 'A radio select input field.',
		options: true,
	},
	'radio': {
		title: 'Radio Select',
		description: 'A radio select input field.',
		options: true,
	},
	'checkbox': {
		title: 'Checkbox',
		description: 'A checkbox input field.',
		isBoolean: true,
	},
	'toggle-vertical': {
		title: 'Toggle Switch (Vertical)',
		description: 'A toggle switch input field.',
		isBoolean: true,
	},
	'toggle': {
		title: 'Toggle Switch',
		description: 'A toggle switch input field.',
		isBoolean: true,
	},
	'slider': {
		title: 'Slider',
		description: 'A slider input field.',
		options: false,
	},
	'rating': {
		title: 'Rating Stars',
		description: 'A rating stars input field.',
		options: false,
	},
	'date': {
		title: 'Date Input',
		description: 'A date input field.',
		placeholder: true,
	},
	'phone': {
		title: 'Phone Number Input',
		description: 'A phone number input field. Use this to collect phone numbers instead of the default input field.',
		options: false,
		placeholder: true,
	},
	'email': {
		title: 'Email Address Input',
		description: 'An email address input field. Use this to collect emails instead of the default input field.',
		options: false,
		placeholder: true,
	},
} as const;
// Create a union type of the keys from the questionConfigs object
export type SurveyQuestionType = keyof typeof questionConfigs;
// Define the SurveyQuestionConfig using the individual questionConfigs
export const SurveyQuestionConfig: Record<SurveyQuestionType, SurveyQuestionConfigItem> = questionConfigs;

export type SurveyQuestionInsightFormatted = (Partial<SurveyQuestionInsight> & SurveyQuestion) & {
	key: number;
	answerMap: AnswserMap;
	totals: SurveyAnswerInsight;
};

export interface SurveyQuestionInsight {
	questionID: string;
	totalCompletions: number; // Total number of people who answered this question
	lastSubmission: string;
	insights: SurveyAnswerInsight[]; // Insights for THE answer or insights for EACH answer if multiple choice
}

export interface SurveyAnswerInsight {
	answer: string; // Answer representation (A, B, C, etc.) OR the most common answer if not multiple choice
	count: number; // number of times this answer was given
	percentage: number; // percentage of times this answer was given
}

export interface SurveySubmission {
	uid?: string;
	id?: string;
	surveyID?: string;
	phone?: string;
	email?: string;
	name?: string;
	contactID?: string;
	answers?: SurveyAnswer[];
	created?: number;
}

export interface SurveyAnswerWithPII extends SurveyAnswer {
	contactID: string;
	name: string;
	phone: string;
	email: string;
	contactFavStoreName: string;
}

export type SurveyAnswerValueType = string | number | boolean | string[] | null | undefined;
export interface SurveyAnswer {
	id: string;
	questionID: string;
	// nil, string, number, boolean, or array of strings
	value: SurveyAnswerValueType;
	type: SurveyQuestionType;
	created?: string;
}

interface ConfigItem extends React.CSSProperties {
	customCSS?: React.CSSProperties;
	demoCSS?: React.CSSProperties;
}

export type ConfigItemWithText = ConfigItem & {
	displayText?: string;
};

type ConfigItemWithImage = ConfigItem & {
	imageSource?: string;
};

export type InputConfigItem = ConfigItem & {
	label: ConfigItem;
	formItemStyle?: ConfigItem;
	placeholder?: string;
};

export interface SurveyToggles extends UserSettingsToggles {
	avatarLogo?: boolean;
	hideTitle?: boolean;
	hideDescription?: boolean;
	hideStoreName?: boolean;
	hideButtonIcon?: boolean;
	hideHeader?: boolean;
	hideCover?: boolean;
	linearGradient?: boolean;
	solidBackground?: boolean;
	budtenderViewsAsCustomer?: boolean;
}

export interface ThankYouPopupInfo {
	title: string;
	text: string;
	disabled: boolean;
	duration?: number;
	notification?: boolean;
}

export type StoreConfigs = {
	[key: string]: SurveyConfig;
};

export interface SurveyConfig {
	toggles: SurveyToggles;

	iconName: string;
	iconType: string;
	userName: string;
	storeName: string;

	thankYouPopup: ThankYouPopupInfo;

	collectInfoPopup: {
		title: string;
		text: string;

		email: boolean;
		phone: boolean;
	};

	logo: ConfigItemWithImage;
	pageStyle: ConfigItem;
	wrapperStyle: ConfigItem;
	headerStyle: ConfigItemWithImage;
	headerInnerStyle: ConfigItem;
	headerName: ConfigItem;
	buttonWrapper: ConfigItem;
	bodyStyle: ConfigItem;
	footerStyle: ConfigItemWithText;
	termsStyle: ConfigItem;
	formStyle: ConfigItem;
	formItemStyle: ConfigItem;
	formInputsStyle: ConfigItem;
	formHeaderStyle: ConfigItemWithText;
	formBodyStyle: ConfigItemWithText;
	formFooterStyle: ConfigItemWithText;
	formWrapper: ConfigItem;
	descriptionStyle: ConfigItemWithText;
	descriptionSubStyle: ConfigItemWithText;
	titleStyle: ConfigItemWithText;
	buttonSubText: ConfigItemWithText;
	storeNameStyle: ConfigItem;
	alpineStyle: ConfigItem;
	button: ConfigItemWithText;
	input: InputConfigItem;

	storeLocations: StoreConfigs;
}
