import { EntryData, PublicStore, Store } from '@/legacy-types';

export * from './reviews';
export * from './surveys';
export * from './manageSurveysTypes';
export * from './SurveyBuilderTypes';

export const SurveyAndReviewsPageTypes = ['surveys', 'reviews'] as const;
export type SurveyAndReviewsPageType = (typeof SurveyAndReviewsPageTypes)[number];

export interface SurveyAndReviewsPageState {
	page: SurveyAndReviewsPageType;
}

export interface SurveyURLParams {
	uid: string;
	page: SurveyAndReviewsPageType;
}

export type LocationsEntry = EntryData<PublicStore>;

export interface CrewLocationsEntry {
	ids: (string | number)[];
	entities: { [key: string]: Store };
}
