import { SearchDataType } from '../../../hooks/useSearchDebounce';
import { EntryData, LoaderState, LocationsEntry, Store } from '@/legacy-types';
import { Survey_Cleaned, SurveyQuestionInsight, SurveyType } from './surveys';

export const StatusFilterOptions = ['all', 'active', 'archived'] as const;
export type StatusFilterType = (typeof StatusFilterOptions)[number];
export const ManageSurveyPeriods = ['all', '90days', '30days', '15days', '7days'] as const;
export type ManageSurveyPeriodType = (typeof ManageSurveyPeriods)[number];

export interface SurveyLoader extends LoaderState {
	drawerLoading: boolean;
}

export interface ManageSurveyProps {
	type?: 'crew' | 'customer';
	uid?: string;
	searchData: SearchDataType;

	storeID: string | undefined;
	setStoreID: React.Dispatch<React.SetStateAction<string | undefined>>;

	loader: SurveyLoader;
	setLoader: React.Dispatch<React.SetStateAction<SurveyLoader>>;

	filters: ManageSurveyFilters;
	setFilters: React.Dispatch<React.SetStateAction<ManageSurveyFilters>>;

	surveys: ManageSurveyData;
	setSurveys: React.Dispatch<React.SetStateAction<ManageSurveyData>>;

	insights: SurveyQuestionInsightsData;
	setInsights: React.Dispatch<React.SetStateAction<SurveyQuestionInsightsData>>;

	builderTarget: string | undefined;
	setBuilderTarget: React.Dispatch<React.SetStateAction<string | undefined>>;

	insightsTarget: string | undefined;
	setInsightsTarget: React.Dispatch<React.SetStateAction<string | undefined>>;

	state: ManageSurveyState;
	setState: React.Dispatch<React.SetStateAction<ManageSurveyState>>;
}

export interface ManageSurveyState {
	showSearch?: boolean;
	stores: EntryData<Store>;
}
export interface ManageSurveyFilters {
	type: SurveyType;
	period?: ManageSurveyPeriodType;
	dateRange: [number, number] | [];
	locations: string[];
	status: StatusFilterType;
}

export interface ManageSurveyData {
	ids: string[];
	entries: { [key: string]: Survey_Cleaned };
}

export interface SurveyQuestionInsightsData {
	ids: string[];
	entries: { [key: string]: SurveyQuestionInsight[] };
}
