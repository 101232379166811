import { EntryData, LoaderState, Store } from '@/legacy-types';
import { Survey, SurveyConfig, SurveyQuestion } from './surveys';

export const SurveyBuilderTabs = ['general', 'contents', 'questions', 'design', 'share'] as const;
export type SurveyBuilderTab = (typeof SurveyBuilderTabs)[number];

export interface SurveyBuilderState {
	tab: SurveyBuilderTab;
	originalSurvey: Partial<Survey>;
	survey: Partial<Survey>;

	questions: SurveyQuestion[];
}

export interface SurveyBuilderSubProps {
	storeID: string | undefined;

	defaultConfig: SurveyConfig;
	state: SurveyBuilderState;
	storeLocations: EntryData<Store>;
	setState: React.Dispatch<React.SetStateAction<SurveyBuilderState>>;
	updateSurvey: (survey: Partial<Survey>) => void;
	updateConfigProperty: (path: string, value?: any) => void;

	loader: LoaderState;
}
