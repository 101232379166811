import { Survey, SurveyAndReviewsPageType, SurveyAndReviewsPageTypes, SurveyBuilderTab, SurveyBuilderTabs } from '../survey-types';

export * from './surveyConfig';
export * from '../pages/survey/helpers/survey.helpers';

export function getValidSurveyAndReviewPageType(str: string) {
	if (SurveyAndReviewsPageTypes.includes(str as SurveyAndReviewsPageType)) return str as SurveyAndReviewsPageType;
	return 'surveys' as SurveyAndReviewsPageType;
}

export function getValidSurveyBuilderPageType(str: string) {
	if (SurveyBuilderTabs.includes(str as SurveyBuilderTab)) return str as SurveyBuilderTab;
	return 'general' as SurveyBuilderTab;
}

export function validateSurvey(survey: Survey): string[] {
	const errors: string[] = [];

	if (!survey.name) errors.push('Survey name is required.');

	return errors;
}
